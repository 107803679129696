import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I18Service {
  private currentLanguage = new Subject<string>();
  constructor(private translate: TranslateService) {

   }

  changeLocal(local: string) {
    this.translate.use(local);
    this.currentLanguage.next(local);
  }

  getLocalEvent():Observable<string> {
    return this.currentLanguage as Observable<string>
  }

  changeContentAndLayout()
  {
    // this.translate.setDefaultLang(localStorage.getItem('lang') || 'ar');
    // this.translate.use(localStorage.getItem('lang') || 'ar');
    // let subscription = this.getLocalEvent()
    // .subscribe(local => this.translate.use(local))

  }

}
